.popup {

    position:fixed;
    left:0;
    top:0;
    background-color: rgba(0,0,0,0.2);
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;


}
.box {
    position: relative;
    width: 30%;
    min-height: 300px;
    background-color: white;
    align-items: center;
    border-radius: 20px ;
    padding: 20px;
    overflow: auto;
    font-size: medium;


}
.button-close {
    cursor: pointer;
    border: 1px solid #000000;
    border-radius: 20%;
    position: absolute;
    right: 16px;
    top: 16px;
}
.button-edit {

    /*padding: 8px;*/
}
