.login-form .button, .signup-form .button {
    width: 100%;
}

.link-btn{
    background: #ffffff;
    color: #000000;
    text-decoration: underline;
    border: 0;
    padding: 10px;
    margin: 20px;
    font-size: medium;
    border-radius:10px;
    cursor: pointer;

}
.loginsignupform, .login-form, .signup-form {
    font-size: large;
    margin-top: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
label{
    text-align: left;
    padding: 2px 0;


}

input{
    margin: 8px 0;
    border-radius: 10px;
    padding: 12px;

}
