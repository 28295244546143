@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');

*{
  box-sizing: border-box;
  margin:0;
  padding: 0;
  font-family: 'Lato', sans-serif;
}

.main-dishes, .sweets, .breakfasts, .baking, .home {
  display: flex;
  /*align-items: center;*/
  justify-content: center;
  font-size: 3rem;

}

.Header{
  padding: 60px;
  text-align: center;
  background: rgba(73, 199, 39, 0.71);
  color: #000000;
  font-size: 60px;
}
.home {
  margin-top: 33px;
  flex-wrap: wrap;
}
.button {
  background: #A93C3C;
  color: #fff;
  border: 0;
  padding: 10px;
  margin: 10px;
  font-size: medium;
  border-radius:10px;
  cursor: pointer;
}
.addrecipe {
  margin-top: 100px;
  justify-content: center;
  display: flex;
}
