.navbar{
    background-color: #A93C3C;
    height: 80px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

}

.menu-bars{
    margin-left: 2rem;
    font-size: 2rem;
    background: none;
}

.nav-menu {
    background-color: #A93C3C;
    width: 250px;
    height: 100vh;
    display: flex;
    justify-content: center;
    position: fixed;
    top:0;
    left: -100%;
    transition: 850ms;
}

.nav-menu.active{
    left: 0;
    transition: 350ms;
}

.nav-text {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 8px 0 8px 16px;
    list-style: none;
    height: 60px;
}
.header a {
     text-decoration: none;
     color: #f5f5f5;
 }


.nav-text a {
    text-decoration: none;
    color: #f5f5f5;
    font-size: 18px;
    width: 95%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 16px;
    border-radius: 4px;

}

.nav-text a:hover {
    background-color: #b46464;
}

.nav-menu-items {
    width: 100%;
}

.navbar-toggle {
    background-color: #A93C3C;
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
}
span{
    margin-left: 16px;
}
